.collections {
  padding: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  margin: 0 10px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tabs button.active {
  background-color: #5c6ac4;
}

.tabs button:not(.active) {
  border: 1px solid #5c6ac4;
  color: #5c6ac4;
}

.content {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}
