/* ProfileEditPopup.css */
.sidebar {
    z-index: 1100; /* Set a higher z-index */
  }
.profile-edit-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .profile-edit-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    position: relative;
  }
  
  .edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .edit-profile-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .edit-profile-form input,
  .edit-profile-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .edit-profile-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  
  .edit-profile-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-profile-buttons button[type="submit"] {
    background: #007bff;
    color: white;
  }
  
  .edit-profile-buttons button[type="button"] {
    background: #dc3545;
    color: white;
  }
  
  .profile-card {
    position: relative;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-card h3 {
    margin-top: 0;
  }
  
  .profile-card p {
    margin: 5px 0;
  }
  
  .profile-card button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
  }
  
  .modal-content button {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .otp-popup {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    position: relative;
  }
  
  .otp-popup .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .otp-popup h3 {
    margin-top: 0;
  }
  
  .otp-popup input {
    width: calc(100% - 40px);
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .otp-popup .edit-profile-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  
  .otp-popup .edit-profile-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .otp-popup .edit-profile-buttons button[type="button"] {
    background: #dc3545;
    color: white;
  }
  
  .otp-popup .edit-profile-buttons button[type="button"]:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .otp-popup .edit-profile-buttons button[type="submit"] {
    background: #007bff;
    color: white;
  }
  