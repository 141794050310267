.header {
  background-color: #232f3e !important;
  color: white !important;
  z-index: 1000;
}

.header__logo img {
  width: 42px;
  height: 42px;
}

.header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-content {
  flex-grow: 1;
  margin-left: 21vh;
  text-align: center;
}

.account-button {
  margin-right: 5vh;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .header-content {
    margin-left: 0;
    text-align: left;
  }
}
