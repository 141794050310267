.products {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.products-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.products-header input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px; /* Add margin-right to create space between the input and button */
}

.products-header button {
  padding: 10px 20px;
  border: none;
  background-color: #5c6ac4;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.add-product {
  background-color: #008060;
  margin-left: auto; /* Push the 'Add New Product' button to the far right */
}

.products-table-container {
  width: 100%;
  overflow-x: auto;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.products-table th{
  padding: 5px 10px; /* Reduce padding to reduce row height */
  text-align: left;
  border-top: 1px solid #ddd; /* Add horizontal border */
  border-bottom: 1px solid #ddd; /* Add horizontal border */
  font-size: 14px;

}
.products-table td {
  padding: 5px 10px; /* Reduce padding to reduce row height */
  text-align: left;
  border-top: 1px solid #ddd; /* Add horizontal border */
  border-bottom: 1px solid #ddd; /* Add horizontal border */
  font-size: 12px;
}
.products-table th {
  background-color: #f4f6f8;
}

.product-thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.status-toggle {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.status-toggle-enabled {
  color: #008060;
}

.status-toggle.disabled {
  background-color: #d9534f;
  color: white;
}

.action-button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.action-button.edit {
  background-color: #ffce00;
  color: #fff;
}

.action-button.delete {
  background-color: #d9534f;
  color: #fff;
}

.action-button.view {
  background-color: #5bc0de;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .products-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .products-header input,
  .products-header button {
    width: 100%;
    margin: 5px 0;
  }
}

.products-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.products-table {
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content button:first-child {
  background-color: #d9534f;
  color: white;
}

.modal-content button:last-child {
  background-color: #5bc0de;
  color: white;
}
.delete-selected  {
  margin-left: 10px; /* Add space between Delete Selected and Add New Product */

}