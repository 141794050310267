
.collections {
    padding: 20px;
  }
  
  .menu-controls {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .menu-controls input,
  .menu-controls select,
  .menu-controls button {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 4px;
  }
  
  .menu-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .menu-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    width: 300px;
    cursor: pointer;
  }
  
  .menu-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-card-body {
    margin-top: 10px;
  }
  
  .btn {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .md-btn {
    padding: 10px 20px;
    border: none;
    background-color: #5c6ac4;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width: 12vh;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-button-confirm {
    background-color: #5c6ac4;
    color: white;
  }
  
  .modal-button-cancel {
    background-color: #ccc;
    color: black;
  }
  