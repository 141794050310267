.slider-admin {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

.slider-admin h2 {
  margin-bottom: 20px;
  text-align: center;
}

.add-slider-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  background-color: #5c6ac4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-slider-button:hover {
  background-color: #5c6ac4;
}

.slider-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.slider-form input,
.slider-form select,
.slider-form button {
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.slider-form button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.slider-form button:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  font-size: 12px;

}
table th {
  background-color: #f4f4f4;
  font-size: 14px;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

table td button {
  margin: 0 5px;
  background: none;
  border: none;
  cursor: pointer;
}

table td button:hover {
  color: #007bff;
}

.slider-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.status-icon-green {
  color: green;
}

.status-icon-red {
  color: red;
}

.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.popup-message span {
  margin-right: 10px;
}

.popup-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.popup-confirm p {
  margin-bottom: 20px;
}

.popup-confirm button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.form-buttons button {
  margin: 5px;
}

/* Add this media query for responsiveness */
@media (max-width: 768px) {
  .slider-admin table td button {
    display: none;
  }

  .slider-admin table td .action-icons {
    display: flex;
    justify-content: space-between;
  }
}

/* Update the action buttons to use Font Awesome icons */
.slider-admin table td button {
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.slider-admin table td .action-icons {
  display: flex;
  justify-content: center;
}

.slider-admin table td .action-icons button {
  margin: 0 5px;
}

.slider-admin table td .action-icons button:hover {
  color: #007bff;
}

.slider-admin table td .ok-icon {
  color: #5c6ac4;
}

.slider-admin table td .x-icon {
  color: #dc3545;
}

/* Hide the status text and replace it with Font Awesome icons */
.slider-admin table td .status-text {
  display: none;
}
