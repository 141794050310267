.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-button, .page-number {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    background-color: #f4f6f8;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .page-button:disabled {
    cursor: not-allowed;
    background-color: #ddd;
  }
  
  .page-number.active {
    background-color: #5c6ac4;
    color: white;
  }
  
  .page-button:hover:not(:disabled), .page-number:hover {
    background-color: #ddd;
  }
  