.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent horizontal scrolling */
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #232f3e;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.home-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 200px;
  background-color: #232f3e;
  height: 100%;
  position: sticky;
  top: 0;
  overflow-y: auto;
  transition: width 0.3s;
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f1f1f1;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    width: 100%;
  }
}
