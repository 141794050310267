.customer-list {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .customer-list h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .customer-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .customer-header input[type="text"] {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1;
  }
  
  .customer-header button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .customer-header button:hover {
    background-color: #0056b3;
  }
  
  .customer-table-container {
    overflow-x: auto;
  }
  
  .customer-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .customer-table th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  .customer-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
  }
  .customer-table th {
    background-color: #f8f8f8;
    color: #333;
  }
  
  .customer-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .status-true {
    color: green;
  }
  
  .status-false {
    color: red;
  }
  .status-icon-active {
    color: green;
}

.status-icon-inactive {
    color: red;
}