.product-info-form {
    margin-top: 20px;
  }
  
  .additional-info, .technical-info {
    margin-bottom: 20px;
  }
  
  .info-form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .info-form-row input {
    margin-right: 10px;
  }
  
  .add-form-button, .close-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    margin-left: 10px;
  }
  
  .close-icon {
    font-size: 20px;
  }
  
  .add-form-button:hover, .close-icon:hover {
    text-decoration: underline;
  }
  