/* Orders.css */
.orders {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.orders h2 {
  margin-bottom: 20px;
  color: #333;
}

.orders-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.orders-header input[type="text"] {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
}

.orders-header button {
  padding: 8px 16px;
  border: none;
  background-color: #5c6ac4;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.orders-header button:hover {
  background-color: #5c6ac4;
}

.orders-table-container {
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;

}

.orders-table th{
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px;

}
.orders-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 12px;

}

.orders-table th {
  background-color: #f8f8f8;
  color: #333;
}

.orders-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.orders-table tr td:first-child {
  text-align: center;
}

.orders-table input[type="checkbox"] {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #f1f1f1;
}

.pagination button:focus {
  outline: none;
  background-color: #007bff;
  color: white;
}

.pagination button[disabled] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #007bff;
  color: white;
}
/* Orders.css */

.status-amber {
  background-color: orange;
}

.status-green {
  color: green;
}

.status-red {
  color: red;
}
