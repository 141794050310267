.vendor-details {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .vendor-card, .manager-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    position: relative;
  }
  
  .vendor-card p, .manager-card p {
    margin: 8px 0;
  }
  
  .manager-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .manager-card {
    flex: 1 1 calc(33.333% - 16px);
    box-sizing: border-box;
  }
  
  .manager-card p {
    margin: 8px 0;
  }
  
  .manager-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .status-toggle {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .status-toggle.active {
    background-color: green;
    color: white;
  }
  
  .status-toggle.inactive {
    background-color: red;
    color: white;
  }
  
  .status-toggle[disabled] {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  
  .status-toggle[disabled]:hover::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
  }
  