.size-form {
    margin-top: 20px;
  }
  
  .size-form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .size-form-row input {
    flex: 1;
    margin-right: 10px;
    padding: 8px;
    box-sizing: border-box;
    width: 30%;
  }
  
  .add-size-button, .close-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    margin-left: 10px;
  }
  
  .close-icon {
    font-size: 20px;
  }
  
  .add-size-button:hover, .close-icon:hover {
    text-decoration: underline;
  }
  