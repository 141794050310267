.tab-container {
    display: flex;
  }
  
  .tab-button {
    padding: 8px 16px;
    margin-right: 8px;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .tab-button.selected {
    background-color: #007bff;
    color: white;
  }
  .graph-card {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    height: 300px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 5vh;
  }
  .home-screen {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns layout */
    gap: 20px; /* Gap between cards */
  }
  
  .order-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-size: 15px;
  }
  
  .overall-orders {
    /* Styles for overall orders card */
  }
  
  .cancelled-orders {
    /* Styles for cancelled orders card */
  }
  
  .ongoing-orders {
    /* Styles for ongoing orders card */
  }
  
  .completed-orders {
    /* Styles for completed orders card */
  }
  