/* src/component/account/Register.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
  }
  
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }
  
  .register-box {
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
  }
  
  .register-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #ff9900;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .register-button:hover {
    background-color: #e68a00;
  }
  
  .register-links {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  .register-links a {
    color: #0066c0;
    text-decoration: none;
  }
  
  .register-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .register-container {
      padding: 10px;
    }
  
    .register-box {
      padding: 15px;
    }
  
    .register-title {
      font-size: 20px;
    }
  
    .input-group input {
      padding: 8px;
    }
  
    .register-button {
      padding: 8px;
      font-size: 14px;
    }
  
    .register-links {
      flex-direction: column;
      align-items: center;
    }
  
    .register-links a {
      margin-top: 5px;
    }
  }
  