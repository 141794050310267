.customer-detail {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .customer-detail h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .orders-table th{
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  .orders-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
  }
  .orders-table th {
    background-color: #f8f8f8;
    color: #333;
  }
  