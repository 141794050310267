.product-form {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.product-form h2 {
  margin-bottom: 20px;
}

.product-form form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.product-form form .form-group {
  display: flex;
  flex-direction: column;
}

.product-form form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.product-form form input,
.product-form form textarea,
.product-form form select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.product-form form textarea {
  resize: vertical;
}

.product-form .form-actions {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.product-form .form-actions button[type="button"] {
  margin-left: 10px;
}

.product-form .images-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.product-form .image-preview {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.product-form .image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-form .image-preview .remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-form .image-preview .remove-image:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Collection input styling */
.collection-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.collection-item {
  display: flex;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 4px;
  padding: 5px 10px;
}

.collection-item .collection-text {
  margin-right: 5px;
}

.collection-item .remove-collection {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-form form {
    grid-template-columns: 1fr;
  }

  .product-form .form-actions {
    justify-content: flex-start;
  }
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  background-color: #5c6ac4;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}
.cancel-btn {
  background-color: #008060;
  
}